import { waitlistInstance } from 'src/api/axiosInstance';

export interface WaitlistSignupRequest {
  email: string;
  referral_link?: string;
}

export interface WaitlistDetailRequest {
  email: string;
}

export interface WaitlistDetailResponse {
  referral_link: string;
  priority: number;
  amount_referred: number;
}

export const waitlistApi = {
  async signupForWaitlist(params: WaitlistSignupRequest) {
    return waitlistInstance.post<void>('/api/v1/signup', {
      waitlist_id: process.env.REACT_APP_GETWAITLIST_ID,
      email: params.email,
      referral_link: params.referral_link,
    });
  },

  async getWaitlistDetails(params: WaitlistDetailRequest) {
    return waitlistInstance.get<WaitlistDetailResponse>(`/api/v1/signup`, {
      params: {
        waitlist_id: process.env.REACT_APP_GETWAITLIST_ID,
        email: params.email,
      },
    });
  },
};
