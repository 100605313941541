import { animated } from '@react-spring/web';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

import { useGetWaitlistDetailsQuery } from 'src/api/waitlist/query';
import backgroundUrl from 'src/assets/images/background.png';
import bannerUrl from 'src/assets/images/banner.png';
import successCheckUrl from 'src/assets/images/success-check.png';
import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { Copy } from 'src/components/ui/Copy';
import { Field } from 'src/components/ui/Field';
import { Image } from 'src/components/ui/Image';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Icon, IconButton } from 'src/elements/Icon';
import { DisplayLarge, PreTitle, Text } from 'src/elements/Text';
import { Footer } from 'src/layouts/MainLayout/Footer';
import { useViewport } from 'src/utils/hooks/useViewport';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  padding-bottom: 16px;

  @media only screen and (min-width: 1032px) {
    padding: 64px;
  }
`;

const CardContainer = styled.div<{ $imageUrl: string }>`
  display: flex;
  flex: 1;
  background-image: url(${({ $imageUrl }) => $imageUrl}), ${({ theme }) => theme.colors.bgBrand};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
  border-radius: 24px;
  padding: 32px 8px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ImageContainer = styled.div`
  width: 70%;
  height: 70%;
`;

const Header = styled(FlexGroup)`
  padding: 16px 8px 8px;
`;

const QueueCard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.bgLightGrey};
  border-radius: 24px;
  padding: 24px 16px;
  gap: 12px;
  flex-direction: column;
`;

export const Success = () => {
  const titleFadeAnimation = useFadeInAndSlideSpring({ delay: 0 });
  const textFadeAnimation = useFadeInAndSlideSpring({ delay: 110 });
  const { isLaptopUp } = useViewport();
  const [search] = useSearchParams();

  const { data: queue } = useGetWaitlistDetailsQuery({ email: search.get('email') ?? '' });

  return (
    <Container>
      <Helmet>
        <title>AI Bae | Find your AI soulmate</title>
        <meta property="og:title" content="AI Bae | Find your AI soulmate" />
        <meta property="og:description" content="Be part of the internet's hottest AI dating experiment" />
        <meta property="og:image" content={bannerUrl} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Content>
        <Header $flex={0}>
          <IconButton
            $variant="clear"
            name="volume_up"
            label="Play music"
            $color="darkPrimary"
            $filled
            $size="bodyLarge"
            // TODO: Implement once music is available (or removed).
            onClick={console.log}
          />

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <LogoPill />
          </div>

          <SocialIcons />
        </Header>

        {!isLaptopUp && (
          <CardContainer $imageUrl={backgroundUrl}>
            <animated.div style={{ width: 50, ...titleFadeAnimation }}>
              <Image src={successCheckUrl} alt="Banner" ratio={1} />
            </animated.div>

            <DisplayLarge as={animated.h1} style={titleFadeAnimation} $color="brandSecondary" $centered>
              YOU'VE SIGNED UP FOR THE WAITLIST
            </DisplayLarge>

            <Text as={animated.span} style={titleFadeAnimation} $centered $color="darkSecondary">
              Share and refer your friends to move up in line!
            </Text>
          </CardContainer>
        )}

        <FlexGroup $vertical $flex={0} $gap={32} style={{ padding: 8 }}>
          {isLaptopUp && (
            <Fragment>
              <DisplayLarge as={animated.h1} style={titleFadeAnimation} $color="brandSecondary">
                YOU'VE SIGNED UP FOR THE WAITLIST
              </DisplayLarge>

              <Text as={animated.span} style={titleFadeAnimation} $centered $color="darkSecondary">
                Share and refer your friends to move up in line!
              </Text>
            </Fragment>
          )}

          <FlexGroup as={animated.div} $vertical $flex={0} $gap={8} style={textFadeAnimation}>
            <QueueCard>
              <PreTitle $color="darkSecondary">REFERRAL LINK</PreTitle>

              <Field
                name="referral_link"
                value={queue?.referral_link ?? ''}
                readOnly
                $size="bodyMedium"
                suffix={<Copy value={queue?.referral_link ?? ''} />}
              />
            </QueueCard>

            <FlexGroup $gap={6}>
              <QueueCard>
                <Icon name="person" label="Queue position" $color="brandSecondary" $filled $size="bodyLarge" />

                <PreTitle $color="darkSecondary">YOUR POSITION</PreTitle>

                <PreTitle $color="darkPrimary" $size="titleMedium">
                  {(queue?.priority ?? 0).toLocaleString()}
                </PreTitle>
              </QueueCard>

              <QueueCard>
                <Icon name="person_add" label="Referrals" $color="brandSecondary" $filled $size="bodyLarge" />

                <PreTitle $color="darkSecondary">REFERRALS</PreTitle>

                <PreTitle $color="darkPrimary" $size="titleMedium">
                  {(queue?.amount_referred ?? 0).toLocaleString()}
                </PreTitle>
              </QueueCard>
            </FlexGroup>
          </FlexGroup>
        </FlexGroup>

        <div style={{ padding: 8 }}>
          <Footer />
        </div>
      </Content>

      {isLaptopUp && (
        <CardContainer $imageUrl={backgroundUrl}>
          <ImageContainer as={animated.div} style={titleFadeAnimation}>
            <Image src={bannerUrl} alt="Banner" ratio={1} />
          </ImageContainer>
        </CardContainer>
      )}
    </Container>
  );
};
