import { animated } from '@react-spring/web';
import { styled } from 'styled-components';

import backgroundUrl from 'src/assets/images/background.png';
import bannerUrl from 'src/assets/images/banner.png';
import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { Image } from 'src/components/ui/Image';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { useViewport } from 'src/utils/hooks/useViewport';

const Container = styled.aside<{ $imageUrl: string }>`
  display: flex;
  flex: 1;
  background-image: url(${({ $imageUrl }) => $imageUrl}), ${({ theme }) => theme.colors.bgBrand};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
  border-radius: 24px;
  padding: 8px;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1032px) {
    margin: 4px;
  }
`;

const ImageContainer = styled.div`
  width: 70%;
  height: 70%;

  @media only screen and (max-width: 1032px) {
    width: 100%;
    height: 100%;
  }
`;

export const SideContent = () => {
  const fadeAnimation = useFadeInAndSlideSpring({ delay: 0 });
  const { isLaptopUp } = useViewport();

  return (
    <Container $imageUrl={backgroundUrl}>
      {!isLaptopUp && (
        <FlexGroup>
          <LogoPill width={100} />

          <Spacer />

          <SocialIcons />
        </FlexGroup>
      )}

      <ImageContainer as={animated.div} style={fadeAnimation}>
        <Image src={bannerUrl} alt="Banner" ratio={1} />
      </ImageContainer>
    </Container>
  );
};
