import axios from 'axios';
import styled from 'styled-components';

import { SoulmateDetails } from 'src/api/soulmate/api';
import { Copy } from 'src/components/ui/Copy';
import { Dialog } from 'src/components/ui/Dialog';
import { Field } from 'src/components/ui/Field';
import { Image } from 'src/components/ui/Image';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Separator } from 'src/elements/Separator';
import { PreTitle, Text, TitleMedium } from 'src/elements/Text';

import { ShareButtons } from './ShareButtons';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const ImagePreview = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 24px;
  border: 2px solid ${({ theme }) => theme.colors.bgDark};
  overflow: hidden;
`;

interface Props {
  soulmate: SoulmateDetails;
}

export const ShareDialog = ({ soulmate }: Props) => {
  const soulmateUrl = window.location.href;

  const downloadImage = async () => {
    const res = await axios.get(soulmate.imageUrl, { responseType: 'blob' });
    const url = window.URL.createObjectURL(res.data);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${soulmate.name}.png`;
    link.click();
  };

  return (
    <Dialog>
      {() => (
        <Content>
          <ImagePreview>
            <Image src={soulmate.imageUrl} alt={soulmate.name} ratio={1} />
          </ImagePreview>

          <TitleMedium $color="brandSecondary">LINK TO PROFILE</TitleMedium>

          <FlexGroup $flex={0} $vertical $gap={16}>
            <Field
              name="soulmate_url"
              value={soulmateUrl}
              readOnly
              $size="bodyMedium"
              suffix={<Copy value={soulmateUrl} />}
            />

            <Text $centered $color="darkSecondary">
              Share this link and your friends can instantly view your AI Bae.
            </Text>
          </FlexGroup>

          <Separator />

          <FlexGroup $flex={0} $vertical $gap={16}>
            <Text $centered $color="darkSecondary">
              Or Download a high-quality, static version of your AI Bae for easy offline sharing and showcasing.
            </Text>

            <Button $size="large" name="soulmate_url" $fullwidth onClick={() => downloadImage()}>
              Download
            </Button>
          </FlexGroup>

          <Separator />

          <FlexGroup $flex={0} $vertical $gap={16} style={{ alignItems: 'center' }}>
            <PreTitle $centered $color="darkPrimary">
              SHARE DIRECTLY ON YOUR SOCIAL MEDIA
            </PreTitle>

            <ShareButtons soulmate={soulmate} />
          </FlexGroup>
        </Content>
      )}
    </Dialog>
  );
};
