import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { GlobalStyle } from 'src/assets/GlobalStyle';
import { ThemeProvider } from 'src/providers/ThemeProvider';

import { queryClient } from './api/queryClient';
import { router } from './router';
import { reportWebVitals } from './utils/debug/reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiProvider>
      <RadixTooltipProvider delayDuration={100}>
        <ThemeProvider>
          <RouterProvider router={router} />

          <GlobalStyle />
        </ThemeProvider>
      </RadixTooltipProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </JotaiProvider>
  </QueryClientProvider>
);

reportWebVitals(console.log);
