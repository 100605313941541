import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { waitlistApi } from './api';

export const useGetWaitlistDetailsQuery = ({ email, enabled = true }: { email: string; enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.waitlist({ email }),
    queryFn: () => waitlistApi.getWaitlistDetails({ email }),
    select: (data) => data.data,
    enabled,
  });

export const useSignupForWaitlistMutation = () => useMutation({ mutationFn: waitlistApi.signupForWaitlist });
