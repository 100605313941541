export const retry = async <T>(
  fn: () => T,
  config: { interval: number; attempts: number } = { interval: 1000, attempts: 4 }
) => {
  let attempts = 0;

  while (attempts < config.attempts) {
    try {
      return await fn();
    } catch (error) {
      attempts++;

      if (attempts === config.attempts) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, config.interval));
    }
  }
};
