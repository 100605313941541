import { DefaultTheme } from 'styled-components';

export const deviceWidths: DefaultTheme['deviceWidths'] = {
  mobile: 480,
  tablet: 768,
  laptop: 1032,
  desktop: 1400,
};

export const fontSizes: DefaultTheme['fontSizes'] = {
  displayLarge: '72px',
  displayMedium: '56px',
  displaySmall: '48px',
  titleLarge: '40px',
  titleMedium: '32px',
  titleSmall: '24px',
  subtitle: '20px',
  bodyLarge: '20px',
  bodyMedium: '16px',
  bodySmall: '14px',
  bodyTiny: '12px',
  bodyMicro: '10px',
};

export const baseTheme: DefaultTheme = {
  colors: {
    primary: '#C8FF3F',
    primaryDark: '#6F9512',
    primaryLight: '#DFFF91',
    primary500: '#89F8C3',

    secondary: '#E93275',
    secondaryDark: '#B91350',
    secondaryLight: '#FF5B97',
    secondary500: '#D4B9FF',

    tertiary: '#32E2C4',
    tertiaryDark: '#23AB94',
    tertiaryLight: '#5EFFE3',

    bgDark: '#131313',
    bgDarkGrey: '#242424',
    bgLight: '#FBF5C4',
    bgLightGrey: '#FFFEF4',
    bgBrand: 'linear-gradient(167.24deg, #C8FF3F 0.04%, #32E2C4 70.51%, #FBF5C4 100%)',

    lightBorderHighlight: '#FFFEF4a4',
    lightBorderDefault: '#FFFEF452',
    lightBorderSubtle: '#FFFEF429',
    darkBorderHighlight: '#242424a4',
    darkBorderDefault: '#24242452',
    darkBorderSubtle: '#24242429',

    icon: '#E93275',
    success: '#78E820',
    error: '#EE2B2B',
    alert: '#F1D024',
  },
  textColors: {
    darkPrimary: '#131313',
    darkSecondary: '#242424a4',

    lightPrimary: '#FFFEF4',
    lightSecondary: '#FFFEF4a4',
    lightTertiary: '#FFFEF452',

    brandPrimary: '#C8FF3Fbb',
    brandPrimaryDark: '#6F9512',
    brandSecondary: '#E93275',
    brandTertiary: '#32E2C4bb',

    icon: '#E93275',
    success: '#78E820',
    error: '#EE2B2B',
    alert: '#F1D024',
  },
  deviceWidths,
  fontSizes,
};

export type Color = keyof DefaultTheme['colors'];
export type TextColor = keyof DefaultTheme['textColors'];
export type FontSize = keyof DefaultTheme['fontSizes'];
