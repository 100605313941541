import { animated } from '@react-spring/web';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { DisplayMedium, Text } from 'src/elements/Text';
import { Footer } from 'src/layouts/MainLayout/Footer';
import { useViewport } from 'src/utils/hooks/useViewport';

import { TikTokLogin } from './TikTokLogin';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px;
  gap: 32px;
  flex: 1;
  overflow: hidden;

  @media only screen and (max-width: 1032px) {
    padding: 32px 16px;
  }
`;

export const Home = () => {
  const titleFadeAnimation = useFadeInAndSlideSpring({ delay: 0 });
  const textFadeAnimation = useFadeInAndSlideSpring({ delay: 110 });

  const { isMobile, isLaptopUp } = useViewport();

  return (
    <Container>
      <Helmet>
        <title>AI Bae | Find your AI soulmate</title>
      </Helmet>

      <Content>
        {isLaptopUp && (
          <FlexGroup $flex={0}>
            <LogoPill />

            <Spacer />

            <SocialIcons />
          </FlexGroup>
        )}

        <FlexGroup $vertical $flex={0} $gap={32}>
          <DisplayMedium as={animated.h1} style={titleFadeAnimation} $color="brandSecondary">
            BE PART OF THE INTERNET'S HOTTEST AI DATING EXPERIMENT
          </DisplayMedium>

          {!isMobile && (
            <Text as={animated.span} style={textFadeAnimation} $size="bodyMedium" $color="darkSecondary">
              Spawn your custom AI Bae, powered by your Social Vibe&mdash;they're blowing up! Get ready to chat, flirt
              and have endless fun in this viral phenomenon that's redefining dating.
            </Text>
          )}

          <TikTokLogin />

          {isMobile && (
            <Text as={animated.span} style={textFadeAnimation} $size="bodyMedium" $color="darkSecondary">
              Spawn your custom AI Bae, powered by your Social Vibe&mdash;they're blowing up! Get ready to chat, flirt
              and have endless fun in this viral phenomenon that's redefining dating.
            </Text>
          )}
        </FlexGroup>

        <Footer />
      </Content>
    </Container>
  );
};
