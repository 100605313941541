import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { deviceWidths } from 'src/assets/theme';
import { Button } from 'src/elements/Button';
import { Text, TitleLarge } from 'src/elements/Text';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 400px;
  margin: 0 auto;

  @media only screen and (max-width: ${deviceWidths.tablet}px) {
    width: 100%;
  }
`;

export const NotFound = () => {
  return (
    <Container>
      <Helmet>
        <title>AI Bae | 404</title>
      </Helmet>

      <TitleLarge $color="darkPrimary">Not found</TitleLarge>

      <Text $centered $color="darkPrimary">
        We're sorry but this page could not be found or you do not have the correct permissions to view it.
      </Text>

      <Link to="/">
        <Button>Go back</Button>
      </Link>
    </Container>
  );
};
