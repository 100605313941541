import { css, styled } from 'styled-components';

import { FontSize } from 'src/assets/theme';

interface Props {
  $hasSuffix?: boolean;
  $hasPrefix?: boolean;
  $size?: FontSize;
}

export const Input = styled.input.attrs((props) => ({
  type: 'text',
  role: 'textbox',
  'aria-disabled': props.disabled ? 'true' : undefined,
  ...props,
}))<Props>`
  padding: 13px 23px;
  width: 100%;

  color: ${({ theme }) => theme.textColors.darkPrimary};
  background: ${({ theme }) => theme.colors.bgLightGrey};
  border-radius: 100px;
  border: 3px solid ${({ theme }) => theme.colors.darkBorderDefault};
  font-size: ${({ theme, $size }) => theme.fontSizes[$size || 'bodyLarge']};
  line-height: 1;
  transition: background 0.2s, border-color 0.2s, box-shadow 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.darkBorderHighlight};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.darkBorderSubtle};
  }

  &::placeholder {
    color: ${({ theme }) => theme.textColors.darkSecondary};
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  &[aria-invalid] {
    border-color: ${({ theme }) => theme.colors.error};
  }

  ${({ $hasSuffix }) => {
    if ($hasSuffix) {
      return css`
        padding-right: 43px;
      `;
    }
  }}

  ${({ $hasPrefix }) => {
    if ($hasPrefix) {
      return css`
        padding-left: 43px;
      `;
    }
  }}
`;
