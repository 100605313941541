import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { useCreateSoulmateMutation } from 'src/api/soulmate/query';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Separator } from 'src/elements/Separator';
import { Svg } from 'src/elements/Svg';
import { PreTitle } from 'src/elements/Text';
import { spotifyTokensAtom, tikTokTokensAtom } from 'src/utils/atoms/socialCredentials';
import { useHideDialog } from 'src/utils/hooks/useDialog';

import { SpotifyLogin } from './SpotifyLogin';

interface Props {
  onError: (error: { message: string }) => void;
}

export const ConnectSocialsDialog = ({ onError }: Props) => {
  const navigate = useNavigate();
  const hide = useHideDialog();

  const [tikTokTokens, setTikTokTokens] = useAtom(tikTokTokensAtom);
  const [spotifyTokens, setSpotifyTokens] = useAtom(spotifyTokensAtom);

  const { mutateAsync: createSoulmate } = useCreateSoulmateMutation();

  const handleFinalize = async () => {
    try {
      if (!tikTokTokens) {
        throw new Error();
      }

      const { data } = await createSoulmate({
        tikTok: tikTokTokens,
        spotify: spotifyTokens,
      });

      setTikTokTokens(undefined);
      setSpotifyTokens(undefined);

      hide();
      navigate(`/@${data.soulmateId}`);
    } catch {
      hide();
      onError({ message: 'There was an issue generating a soulmate, please try again later' });
    }
  };

  return (
    <Dialog>
      {() => (
        <DialogContent
          title="TIKTOK CONNECTED!"
          description="Connect other social media account to enhance your AI Bae's personality."
          buttons={[
            <Button key="skip" $size="large" $variant="secondary" onClick={handleFinalize}>
              Skip
            </Button>,
            <Button key="next" $size="large" $variant="primary" disabled={!spotifyTokens} onClick={handleFinalize}>
              Create AI Bae
            </Button>,
          ]}
        >
          <FlexGroup $vertical $gap={8}>
            <Separator />
            <FlexGroup $gap={8} $flex={0}>
              <Svg $size="bodyLarge" name="tiktok" />

              <PreTitle $size="bodyLarge" $color="darkPrimary">
                TIKTOK
              </PreTitle>

              <Spacer />

              <Icon name="check" $color="success" $size="bodyLarge" label="TiTok connected" />
            </FlexGroup>
            <Separator />
            <SpotifyLogin />
          </FlexGroup>
        </DialogContent>
      )}
    </Dialog>
  );
};
