import { css } from 'styled-components';

export const boxShadow = css`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 38px -10px, rgba(0, 0, 0, 0.15) 0px 10px 20px -15px;
`;

export const animationTiming = css`
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
`;

export const gradientHorizontal = css`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.secondary}
  );
`;

export const gradientHorizontalLeft = css`
  background: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.secondary}
  );
`;

export const gradientVertical = css`
  background: linear-gradient(${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.secondary});
`;
