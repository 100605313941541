import { useAtom } from 'jotai';
import { FunctionComponent } from 'react';

import { activeDialogAtom } from 'src/utils/atoms/ui';

export const useDialog = <T>(Component: FunctionComponent<T>) => {
  const [, setActiveDialog] = useAtom(activeDialogAtom);

  const show = (props: T) => {
    setActiveDialog({ Component, props });
  };

  return show;
};

export const useHideDialog = () => {
  const [, setActiveDialog] = useAtom(activeDialogAtom);

  const hide = () => {
    setActiveDialog({ Component: null, props: {} });
  };

  return hide;
};
