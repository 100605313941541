import { useSpring } from '@react-spring/web';

export const useFadeInAndSlideSpring = ({ delay = 0 }: { delay?: number }) =>
  useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-4px)' },
    delay,
    config: { tension: 210, friction: 20 },
  });
