import styled from 'styled-components';

import { ReactComponent as Talus } from 'src/assets/icons/talus.svg';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { ExternalLink, Link } from 'src/elements/Link';
import { PreTitle } from 'src/elements/Text';

const Container = styled.footer`
  width: 100%;
  padding-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkBorderDefault};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Footer = () => {
  return (
    <Container>
      <FlexGroup $gap={8}>
        <Link to="/privacy-policy">
          <Button $variant="text">Privacy Policy</Button>
        </Link>

        <Link to="/terms-of-service">
          <Button $variant="text">Terms of Service</Button>
        </Link>
      </FlexGroup>

      <FlexGroup $flex={0}>
        <ExternalLink $clear href="https://talus.network" style={{ whiteSpace: 'nowrap' }}>
          <PreTitle $size="bodySmall" $color="darkSecondary">
            POWERED BY&nbsp;
          </PreTitle>

          <Talus style={{ height: 10 }} />
        </ExternalLink>
      </FlexGroup>
    </Container>
  );
};
