import { useState } from 'react';

import { useGetTikTokTokensMutation } from 'src/api/auth/query';
import { retry } from 'src/utils/helpers/retry/retry';

export const useTikTokAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<{ expiresAt: number; accessToken: string; refreshToken: string }>();

  const { mutateAsync: getTikTokTokens } = useGetTikTokTokensMutation();

  const csrfToken = crypto.getRandomValues(new Uint32Array(2)).join('');

  const handleAuthClick = async () => {
    setError(false);
    setLoading(true);

    const clientId = process.env.REACT_APP_TIKTOK_CLIENT_ID ?? '';
    const redirectUri = process.env.REACT_APP_TIKTOK_REDIRECT_URI ?? '';
    const scopes = 'user.info.basic,user.info.profile,user.info.stats,video.list';
    const responseType = 'code';

    const authUrl =
      `https://www.tiktok.com/v2/auth/authorize` +
      `?client_key=${clientId}` +
      `&response_type=${responseType}` +
      `&scope=${scopes}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&state=${csrfToken}`;

    window.open(authUrl, '_blank');
    window.addEventListener('focus', focusListener);
  };

  const focusListener = async () => {
    window.removeEventListener('focus', focusListener);

    let state: string | null = null;
    let code: string | null = null;

    try {
      await retry(() => {
        state = localStorage.getItem('tikTokAuthState');
        code = localStorage.getItem('tikTokAuthCode');

        if (!state || !code) {
          throw new Error('No auth state or code found');
        }
      });

      // Clear local storage.
      localStorage.removeItem('tikTokAuthState');
      localStorage.removeItem('tikTokAuthCode');
    } catch {
      setLoading(false);
      setError(true);

      return;
    }

    if (!code || state !== csrfToken) {
      setLoading(false);
      setError(true);

      return;
    }

    try {
      const data = await getTikTokTokens({ code });

      setData(data);
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);

      return;
    }
  };

  return { loading, error, data, handleAuthClick };
};
