import { useState } from 'react';

import { useGetSpotifyTokensMutation } from 'src/api/auth/query';
import { retry } from 'src/utils/helpers/retry/retry';

export const useSpotifyAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<{ expiresAt: number; accessToken: string; refreshToken: string }>();

  const { mutateAsync: getSpotifyTokens } = useGetSpotifyTokensMutation();

  const csrfToken = crypto.getRandomValues(new Uint32Array(2)).join('');

  const handleAuthClick = async () => {
    setError(false);
    setLoading(true);

    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID ?? '';
    const redirectUri = process.env.REACT_APP_SPOTIFY_REDIRECT_URI ?? '';
    const scopes = 'user-top-read';
    const responseType = 'code';

    const authUrl =
      `https://accounts.spotify.com/authorize` +
      `?client_id=${clientId}` +
      `&response_type=${responseType}` +
      `&scope=${encodeURIComponent(scopes)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&state=${csrfToken}`;

    window.open(authUrl, '_blank');
    window.addEventListener('focus', focusListener);
  };

  const focusListener = async () => {
    window.removeEventListener('focus', focusListener);

    let state: string | null = null;
    let code: string | null = null;

    try {
      await retry(() => {
        state = localStorage.getItem('spotifyAuthState');
        code = localStorage.getItem('spotifyAuthCode');

        if (!state || !code) {
          throw new Error('No auth state or code found');
        }
      });

      // Clear local storage.
      localStorage.removeItem('spotifyAuthState');
      localStorage.removeItem('spotifyAuthCode');
    } catch {
      setLoading(false);
      setError(true);

      return;
    }

    if (!code || state !== csrfToken) {
      setLoading(false);
      setError(true);

      return;
    }

    try {
      const data = await getSpotifyTokens({ code });

      setData(data);
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);

      return;
    }
  };

  return { loading, error, data, handleAuthClick };
};
