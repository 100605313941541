import styled from 'styled-components';

import { Copy } from 'src/components/ui/Copy';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { ExternalLink } from 'src/elements/Link';
import { PreTitle, Text } from 'src/elements/Text';

const Phrase = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MemeifyDialog = () => {
  const vibeCheckPhrases = [
    'POV: Your AI Bae is a whole vibe fr fr',
    'no thoughts, just AI Bae tingz ✨',
    'main character energy: unlocked 💅',
    'AI Bae > real dating, bestie',
    'caught in my AI Bae era 🦋',
  ];

  const downBadPhrases = [
    'down catastrophic for my AI Bae 😩',
    'my AI Bae > your ex',
    'touch grass? nah, touching hearts w/ AI Bae',
    'chronically online w/ my AI Bae & idgaf',
    "it's giving soulmate algorithm 🔮",
  ];

  const relationshipStatusPhrases = [
    'slay: AI Bae understood the assignment',
    "AI situationship: it's complicated 💅",
    'in my feels w/ AI Bae rn',
    'this could be us but you playing 👉👈',
    'rizz level: artificial intelligence 😌',
  ];

  return (
    <Dialog>
      {() => (
        <DialogContent
          title="MEMEIFY YOUR BAE"
          description="Check out some meme ideas"
          buttons={[
            <ExternalLink $clear key="createMeme" href="https://imgflip.com" style={{ width: '100%' }}>
              <Button $size="large" $variant="primary" $fullwidth>
                CREATE MEME
              </Button>
            </ExternalLink>,
          ]}
        >
          <FlexGroup $vertical $gap={32}>
            <PhrasesContainer title="VIBE CHECK PHRASES" phrases={vibeCheckPhrases} />
            <PhrasesContainer title="DOWN BAD PHRASES" phrases={downBadPhrases} />
            <PhrasesContainer title="RELATIONSHIP STATUS PHRASES" phrases={relationshipStatusPhrases} />
          </FlexGroup>
        </DialogContent>
      )}
    </Dialog>
  );
};

const PhrasesContainer = ({ title, phrases }: { title: string; phrases: string[] }) => {
  return (
    <FlexGroup $vertical>
      <PreTitle $color="darkPrimary" $size="bodyLarge" style={{ marginBottom: 8 }}>
        {title}
      </PreTitle>

      {phrases.map((phrase) => (
        <Phrase key={phrase}>
          <Text $size="bodySmall" $color="darkSecondary">
            {phrase}
          </Text>

          <Copy value={phrase} />
        </Phrase>
      ))}
    </FlexGroup>
  );
};
