import logoPillUrl from 'src/assets/images/aibae-pill.png';

import { Image } from '../ui/Image';

interface Props {
  width?: number;
}

export const LogoPill = ({ width = 121 }: Props) => {
  return (
    <div style={{ width }}>
      <Image src={logoPillUrl} alt="aibae.me" ratio={3.369} />
    </div>
  );
};
