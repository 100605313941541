import { authInstance } from 'src/api/axiosInstance';

export const authApi = {
  getTikTokTokens: async ({ code }: { code: string }) => {
    const { data } = await authInstance.post('/token/tiktok', {
      code,
      redirect_uri: process.env.REACT_APP_TIKTOK_REDIRECT_URI,
    });

    if (!data.expires_in || !data.access_token || !data.refresh_token) {
      throw new Error('Invalid response');
    }

    return {
      expiresAt: Math.floor(Date.now() / 1000 + data.expires_in),
      accessToken: data.access_token as string,
      refreshToken: data.refresh_token as string,
    };
  },

  getSpotifyTokens: async ({ code }: { code: string }) => {
    const { data } = await authInstance.post('/token/spotify', {
      code,
      redirect_uri: process.env.REACT_APP_SPOTIFY_REDIRECT_URI,
    });

    if (!data.expires_in || !data.access_token || !data.refresh_token) {
      throw new Error('Invalid response');
    }

    return {
      expiresAt: Math.floor(Date.now() / 1000 + data.expires_in),
      accessToken: data.access_token as string,
      refreshToken: data.refresh_token as string,
    };
  },

  getTwitterTokens: async ({ code, challenge }: { code: string; challenge: string }) => {
    const { data } = await authInstance.post('/token/twitter', {
      code,
      challenge,
      redirect_uri: process.env.REACT_APP_TWITTER_REDIRECT_URI,
    });

    if (!data.expires_in || !data.access_token) {
      throw new Error('Invalid response');
    }

    return {
      expiresAt: Math.floor(Date.now() / 1000 + data.expires_in),
      accessToken: data.access_token as string,
    };
  },
};
