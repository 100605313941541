import axios from 'axios';

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

const soulmateInstance = axios.create({
  baseURL: process.env.REACT_APP_SOULMATE_API_URL,
});

const waitlistInstance = axios.create({
  baseURL: 'https://api.getwaitlist.com',
});

export { authInstance, soulmateInstance, waitlistInstance };
