import { forwardRef, FunctionComponent } from 'react';
import { css, styled } from 'styled-components';

import { ReactComponent as Chat } from 'src/assets/icons/chat.svg';
import { ReactComponent as Discover } from 'src/assets/icons/discover.svg';
import { ReactComponent as Instagram } from 'src/assets/icons/instagram.svg';
import { ReactComponent as Memeify } from 'src/assets/icons/memeify.svg';
import { ReactComponent as Share } from 'src/assets/icons/share.svg';
import { ReactComponent as Spark } from 'src/assets/icons/spark.svg';
import { ReactComponent as Spotify } from 'src/assets/icons/spotify.svg';
import { ReactComponent as Telegram } from 'src/assets/icons/telegram.svg';
import { ReactComponent as TikTok } from 'src/assets/icons/tiktok.svg';
import { ReactComponent as Twitter } from 'src/assets/icons/twitter.svg';
import { ReactComponent as WhatsApp } from 'src/assets/icons/whatsapp.svg';
import { FontSize, TextColor } from 'src/assets/theme';

interface Props {
  name: SvgName;
  $color?: TextColor;
  $size?: FontSize;
}

const Base = styled.span.attrs((props) => ({
  role: 'img',
  ...props,
}))<Omit<Props, 'label' | 'name'>>`
  user-select: none;
  color: ${({ $color, theme }) => ($color ? theme.textColors[$color] : 'inherit')};
  fill: ${({ $color, theme }) => ($color ? theme.textColors[$color] : 'inherit')};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $size, theme }) => {
    return css`
      width: calc(${theme.fontSizes[$size ?? 'bodyMedium']} + 3px);
      height: calc(${theme.fontSizes[$size ?? 'bodyMedium']} + 3px);
    `;
  }}
`;

const map: Record<string, FunctionComponent<{}>> = {
  spotify: Spotify,
  tiktok: TikTok,
  twitter: Twitter,
  instagram: Instagram,
  whatsapp: WhatsApp,
  telegram: Telegram,
  chat: Chat,
  discover: Discover,
  spark: Spark,
  share: Share,
  memeify: Memeify,
};

export type SvgName = keyof typeof map;

export const Svg = forwardRef<HTMLSpanElement, Props>(({ name, ...rest }, ref) => {
  const Component = map[name];

  return (
    <Base ref={ref} {...rest}>
      <Component />
    </Base>
  );
});
