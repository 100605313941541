import copy from 'copy-to-clipboard';

import { IconButton } from 'src/elements/Icon';
import { Text } from 'src/elements/Text';

import { Popover } from './Popover';

interface Props {
  value: string;
}

export const Copy = ({ value }: Props) => {
  return (
    <Popover
      sideOffset={2}
      trigger={
        <IconButton
          $color="darkSecondary"
          name="content_copy"
          label="Copy"
          $variant="clear"
          onClick={() => copy(value)}
        />
      }
    >
      <Text $color="lightPrimary">Copied!</Text>
    </Popover>
  );
};
