import { css, styled } from 'styled-components';

import { FontSize, TextColor } from 'src/assets/theme';

interface Props {
  $color?: TextColor;
  $size?: FontSize;
  $weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  $uppercase?: boolean;
  $underline?: boolean;
  $italic?: boolean;
  $centered?: boolean;
}

export const Text = styled.span<Props>`
  color: ${({ $color, theme }) => ($color ? theme.textColors[$color] : 'inherit')};
  font-size: ${({ $size, theme }) => ($size ? theme.fontSizes[$size] : 'inherit')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 'inherit')};

  ${({ $uppercase }) => {
    if ($uppercase) {
      return css`
        text-transform: uppercase;
      `;
    }
  }}

  ${({ $underline }) => {
    if ($underline) {
      return css`
        text-decoration: underline;
      `;
    }
  }}

  ${({ $italic }) => {
    if ($italic) {
      return css`
        font-style: italic;
      `;
    }
  }}

  ${({ $centered }) => {
    if ($centered) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

export const DisplayLarge = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h1',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'displayLarge']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleLarge']};
  }
`;

export const DisplayMedium = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h2',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'displayMedium']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleMedium']};
  }
`;

export const DisplaySmall = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h3',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'displaySmall']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleSmall']};
  }
`;

export const TitleLarge = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h4',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleLarge']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleSmall']};
  }
`;

export const TitleMedium = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h5',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleMedium']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleSmall']};
  }
`;

export const TitleSmall = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h6',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleSmall']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'titleSmall']};
  }
`;

export const Subtitle = styled(Text)<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'subtitle']};
  line-height: 90%;
  letter-spacing: -2px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};

  @media only screen and (max-width: ${({ theme }) => theme.deviceWidths.mobile}px) {
    font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'subtitle']};
  }
`;

export const PreTitle = styled(Text)<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'bodySmall']};
  line-height: 90%;
  letter-spacing: -1px;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  font-weight: ${({ $weight, theme }) => $weight ?? '500'};
`;
