import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { DialogProvider } from 'src/providers/DialogProvider';

import { SideContent } from './SideContent';

const Container = styled.div`
  display: flex;
  min-height: 100dvh;

  @media only screen and (max-width: 1032px) {
    flex-direction: column-reverse;
  }
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MainLayout = () => {
  const { pathname } = useLocation();

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <DialogProvider />

      <Content>
        <Outlet />
      </Content>

      <SideContent />
    </Container>
  );
};
