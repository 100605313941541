import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';
import { RefetchInterval } from 'src/api/types';

import { soulmateApi, SoulmateProgress } from './api';

export const useGetSoulmateProgressQuery = ({
  soulmateId,
  refetchInterval,
  enabled = true,
}: {
  soulmateId: string;
  refetchInterval?: RefetchInterval<SoulmateProgress>;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.soulmateProgress({ soulmateId }),
    queryFn: () => soulmateApi.getSoulmateProgress({ soulmateId }),
    select: (data) => data.data,
    refetchInterval,
    enabled,
  });

export const useGetSoulmateQuery = ({ soulmateId, enabled = true }: { soulmateId: string; enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.soulmate({ soulmateId }),
    queryFn: () => soulmateApi.getSoulmate({ soulmateId }),
    select: (data) => data.data,
    enabled,
  });

export const useCreateSoulmateMutation = () => useMutation({ mutationFn: soulmateApi.createSoulmate });
