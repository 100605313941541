import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useSignupForWaitlistMutation } from 'src/api/waitlist/query';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { WaitlistForm } from 'src/pages/waitlist/Signup';

const schema = z.object({
  email: z.string().trim().email(),
});

type SignupFormSchema = z.infer<typeof schema>;

export const ChatDialog = () => {
  const navigate = useNavigate();
  const { mutateAsync: signupForWaitlist } = useSignupForWaitlistMutation();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SignupFormSchema>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: { email: '' },
  });

  const performSignup = async ({ email }: SignupFormSchema) => {
    try {
      await signupForWaitlist({ email, referral_link: window.location.href });

      navigate('/waitlist/success?email=' + encodeURIComponent(email));
    } catch {
      setError('email', { message: 'An error occurred. Please try again.' });
    }
  };

  return (
    <Dialog>
      {() => (
        <DialogContent
          title="YOUR AI BAE IS GETTING CHATTY!"
          description="Be among the first to chat with your AI Bae when the feature drops!"
        >
          <form style={{ display: 'flex', flex: 1 }} onSubmit={handleSubmit(performSignup)}>
            <WaitlistForm errors={errors} isSubmitting={isSubmitting} isValid={isValid} register={register} />
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};
