import styled from 'styled-components';

import { SoulmateDetails } from 'src/api/soulmate/api';
import { Button } from 'src/elements/Button';
import { ExternalLink } from 'src/elements/Link';
import { Svg } from 'src/elements/Svg';

interface Props {
  soulmate: SoulmateDetails;
}

const Container = styled.div`
  display: flex;
  gap: 16px;
`;

export const ShareButtons = ({ soulmate }: Props) => {
  const text = `Check out my AI Bae, ${soulmate.name}!`;
  const url = window.location.href;

  return (
    <Container>
      <TwitterShareButton text={text} url={url} />

      <WhatsAppShareButton text={text} url={url} />

      <TelegramShareButton text={text} url={url} />

      <InstagramButton />

      <TikTokButton />
    </Container>
  );
};

interface ShareButtonProps {
  text: string;
  url: string;
}

const TwitterShareButton = ({ text, url }: ShareButtonProps) => {
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;

  return (
    <ExternalLink href={twitterUrl} $clear>
      <Button $size="large" $variant="secondary" $round>
        <Svg name="twitter" />
      </Button>
    </ExternalLink>
  );
};

const WhatsAppShareButton = ({ text, url }: ShareButtonProps) => {
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;

  return (
    <ExternalLink href={whatsappUrl} $clear>
      <Button $size="large" $variant="secondary" $round>
        <Svg name="whatsapp" />
      </Button>
    </ExternalLink>
  );
};

const TelegramShareButton = ({ text, url }: ShareButtonProps) => {
  const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

  return (
    <ExternalLink href={telegramUrl} $clear>
      <Button $size="large" $variant="secondary" $round>
        <Svg name="telegram" />
      </Button>
    </ExternalLink>
  );
};

const InstagramButton = () => {
  const instagramUrl = `https://www.instagram.com`;

  return (
    <ExternalLink href={instagramUrl} $clear>
      <Button $size="large" $variant="secondary" $round>
        <Svg name="instagram" />
      </Button>
    </ExternalLink>
  );
};

const TikTokButton = () => {
  const tiktokUrl = `https://www.tiktok.com`;

  return (
    <ExternalLink href={tiktokUrl} $clear>
      <Button $size="large" $variant="secondary" $round>
        <Svg name="tiktok" />
      </Button>
    </ExternalLink>
  );
};
