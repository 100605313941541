import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';

export const DiscoverDialog = () => {
  return (
    <Dialog>
      {() => (
        <DialogContent
          title="DISCOVER OTHER BAES"
          description="Memeify and react!"
          buttons={[
            <Button key="next" $size="large" $variant="primary">
              Action
            </Button>,
          ]}
        >
          <FlexGroup $vertical $gap={8}>
            ...
          </FlexGroup>
        </DialogContent>
      )}
    </Dialog>
  );
};
