import * as RadixAspectRatio from '@radix-ui/react-aspect-ratio';
import { useState } from 'react';
import { BlurhashCanvas } from 'react-blurhash';
import { styled } from 'styled-components';

import { animationTiming } from 'src/assets/mixins';

interface Props {
  src: string;
  alt: string;
  blurhash?: string;
  ratio?: number;
}

const Root = styled(RadixAspectRatio.Root)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Fallback = styled.canvas<{ $show: boolean }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s;

  ${({ $show }) => {
    if ($show) {
      return `opacity: 1;`;
    }
  }};

  ${animationTiming}
`;

export const Image = ({ src, alt, blurhash, ratio = 16 / 9 }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Root ratio={ratio}>
      <img src={src} alt={alt} onLoad={() => setLoaded(true)} />

      {blurhash && <Fallback as={BlurhashCanvas} $show={!loaded} hash={blurhash} />}
    </Root>
  );
};
