import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Svg } from 'src/elements/Svg';
import { PreTitle, Text } from 'src/elements/Text';
import { spotifyTokensAtom } from 'src/utils/atoms/socialCredentials';
import { useSpotifyAuth } from 'src/utils/hooks/useSpotifyAuth';

export const SpotifyLogin = () => {
  const [spotifyTokens, setSpotifyTokens] = useAtom(spotifyTokensAtom);
  const { loading, error, data = spotifyTokens, handleAuthClick } = useSpotifyAuth();

  useEffect(() => {
    if (data) {
      setSpotifyTokens(data);
    }
  }, [data, setSpotifyTokens]);

  return (
    <FlexGroup $vertical $gap={8} $flex={0}>
      <FlexGroup $gap={8} $flex={0}>
        <Svg $size="bodyLarge" name="spotify" />

        <PreTitle $size="bodyLarge" $color="darkPrimary">
          SPOTIFY
        </PreTitle>

        <Spacer />

        {!data && (
          <Button $loading={loading} disabled={!!data} onClick={handleAuthClick}>
            CONNECT
          </Button>
        )}

        {data && <Icon name="check" $color="success" $size="bodyLarge" label="Spotify connected" />}
      </FlexGroup>

      {error && (
        <Text $color="error" role="alert" style={{ marginLeft: 16 }}>
          There was an issue authenticating, please try again later
        </Text>
      )}
    </FlexGroup>
  );
};
