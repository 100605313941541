import * as RadixLabel from '@radix-ui/react-label';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { styled } from 'styled-components';

import { FontSize } from 'src/assets/theme';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Input } from 'src/elements/Input';
import { PreTitle, Text } from 'src/elements/Text';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  name: string;
  error?: FieldError;
  icon?: ReactNode;
  suffix?: ReactNode;
  $size?: FontSize;
}

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
`;

const IconContainer = styled.span`
  position: absolute;
  left: 6px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;

const SuffixContainer = styled.span`
  position: absolute;
  right: 6px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Field = forwardRef<HTMLInputElement, Props>(({ label, name, error, icon, suffix, ...rest }, ref) => {
  return (
    <Fieldset>
      {label && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PreTitle as={RadixLabel.Root} htmlFor={name} $color="darkPrimary" $uppercase $size="bodyMedium">
            {label}
          </PreTitle>
        </div>
      )}

      <Input
        $hasSuffix={!!suffix}
        $hasPrefix={!!icon}
        ref={ref}
        id={name}
        name={name}
        aria-invalid={error ? 'true' : undefined}
        {...rest}
      />

      {suffix && (
        <SuffixContainer>
          <Text $color="darkSecondary">{suffix}</Text>
        </SuffixContainer>
      )}

      {icon && <IconContainer>{icon}</IconContainer>}

      {error && (
        <FlexGroup $vertical $gap={6} style={{ marginLeft: 26 }}>
          <Text $color="error" role="alert">
            {error.message}
          </Text>
        </FlexGroup>
      )}
    </Fieldset>
  );
});
