import { createBrowserRouter, useSearchParams } from 'react-router-dom';

import { NotFound } from 'src/pages/fallback/NotFound';
import { SomethingWentWrong } from 'src/pages/fallback/SomethingWentWrong';
import { Home } from 'src/pages/home/Home';

import { CardLayout } from './layouts/CardLayout/CardLayout';
import { ClearLayout } from './layouts/ClearLayout/ClearLayout';
import { MainLayout } from './layouts/MainLayout/MainLayout';
import { WaitlistLayout } from './layouts/WaitlistLayout/WaitlistLayout';

export const router = createBrowserRouter([
  {
    path: '',
    element: <MainLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        element: <Home />,
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/tiktok/callback',
        Component: () => {
          const [search] = useSearchParams();
          const code = search.get('code');
          const state = search.get('state');

          if (code && state) {
            localStorage.setItem('tikTokAuthCode', code);
            localStorage.setItem('tikTokAuthState', state);
          }

          window.close();

          return null;
        },
      },
      {
        path: '/spotify/callback',
        Component: () => {
          const [search] = useSearchParams();
          const code = search.get('code');
          const state = search.get('state');

          if (code && state) {
            localStorage.setItem('spotifyAuthCode', code);
            localStorage.setItem('spotifyAuthState', state);
          }

          window.close();

          return null;
        },
      },
      {
        path: '/twitter/callback',
        Component: () => {
          const [search] = useSearchParams();
          const code = search.get('code');
          const state = search.get('state');

          if (code && state) {
            localStorage.setItem('twitterAuthCode', code);
            localStorage.setItem('twitterAuthState', state);
          }

          window.close();

          return null;
        },
      },

      {
        path: '*',
        element: <NotFound />,
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
  {
    path: '/privacy-policy',
    element: <ClearLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        lazy: () =>
          import('src/pages/legal/PrivacyPolicy').then(({ PrivacyPolicy }) => ({
            element: <PrivacyPolicy />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
  {
    path: '/terms-of-service',
    element: <ClearLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        lazy: () =>
          import('src/pages/legal/TermsOfService').then(({ TermsOfService }) => ({
            element: <TermsOfService />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
  {
    path: '/waitlist',
    element: <WaitlistLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        lazy: () =>
          import('src/pages/waitlist/Waitlist').then(({ Waitlist }) => ({
            element: <Waitlist />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: 'web3',
        lazy: () =>
          import('src/pages/waitlist/Waitlist').then(({ Waitlist }) => ({
            element: <Waitlist />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: 'signup',
        lazy: () =>
          import('src/pages/waitlist/Signup').then(({ Signup }) => ({
            element: <Signup />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: 'success',
        lazy: () =>
          import('src/pages/waitlist/Success').then(({ Success }) => ({
            element: <Success />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
  {
    path: '/:handle',
    element: <CardLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        lazy: () =>
          import('src/pages/soulmate/Soulmate').then(({ Soulmate }) => ({
            element: <Soulmate />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
  { path: '*', element: <NotFound />, ErrorBoundary: SomethingWentWrong },
]);
